import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
} from '@angular/core';
import {
  provideDefaultClientTableData,
  useDefaultClientTableService,
} from '../service';
import { PwCacheStatus } from '@pwiz/infra/cache/ts';
import { outputFromObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'pw-ui-table-data-provider',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'pw-scroll-container pw-scroll',
  },
  template: `<ng-content />`,
  providers: provideDefaultClientTableData(),
})
export class UiTableDataProviderComponent<T> {
  #dataService = useDefaultClientTableService<T>();
  rowData = input.required<T[] | null>();
  fetchStatus = input.required<PwCacheStatus>();
  rowBuffer = input<number>();

  rowClick = outputFromObservable(this.#dataService.onEvent$('rowClick'));

  constructor() {
    effect(() => (this.#dataService.status = this.fetchStatus()), {
      allowSignalWrites: true,
    });
    effect(() => (this.#dataService.items = this.rowData() || []), {
      allowSignalWrites: true,
    });
    effect(() => {
      if (this.rowBuffer() !== undefined) {
        this.#dataService.rowBuffer = this.rowBuffer()!;
      }
    });
  }
}
